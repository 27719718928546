<script>
import planets from '@/assets/img/menu-planets.svg';
import Button from '../components/Button';

export default {
  name: 'NotFound',
  data: () => ({
    planets,
  }),
  components: { Button },
};
</script>

<template>
  <div>
    <div class="main-central">
      <div class="main-central__container">
        <img :src="planets" class="planets"/>
        <div class="not-found-content">
          <span class="title">{{ $t('not-found.title') }}</span>
          <span class="description">{{ $t('not-found.description') }}</span>
          <Button to="/">
            {{ $t('not-found.button') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .planets {
    height: 220px;
    position: relative;
    margin-bottom: -120px;
  }
  .menu-cow {
    position: relative;
    z-index: -1;
    margin-top: -400px;
    margin-bottom: -120px;
  }

  .main-central {
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .main-central__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .not-found-content {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    align-items: center;
    width: 90%;

    .title {
      font-weight: 900;
      font-size: 44px;
      text-align: center;
      color: $main-pink;
      margin-bottom: 22px;
    }

    .description {
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: $main-pink;
      margin-bottom: 57px;
    }

    .btn {
      width: 260px;
    }
  }
</style>
